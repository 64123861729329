/* global newrelic */
import Debug from './logger/Debug';

const timingUtil = {
    markUserTime(timingName) {
        // client only code
        if (typeof window !== 'undefined') {
            if (typeof newrelic !== 'undefined') {
                const now = new Date().getTime();
                const timingData = { name: timingName, start: now };
                newrelic.addToTrace(timingData);
            } else {
                Debug.warn('timingUtil', 'NewRelic undefined');
            }
        }
    },
};

export default timingUtil;
