import React, { useEffect } from 'react';

import dynamic from 'next/dynamic';

import { useFeatures } from 'reaxl-features';

// Utilities
import timingUtil from '@/utilities/timingUtil';

import LazyComponent from '@/components/LazyComponent';

const FooterContentInjector = dynamic(() => import('reaxl-footer/es/FooterContentInjector'), { ssr: false });

function FooterContainer() {
    const {
        disable_ads: [disableAds],
        disable_thirdparty: [disableThirdParty],
        opinion_lab: [isOpinionLabEnabled],
    } = useFeatures([
        'disable_ads',
        'disable_thirdparty',
        'opinion_lab',
    ]);

    useEffect(() => {
        timingUtil.markUserTime('footer-rendered');
    }, []);

    if (isOpinionLabEnabled) {
        return (
            <LazyComponent>
                <FooterContentInjector renderThirdParty={!disableThirdParty && !disableAds} />
            </LazyComponent>
        );
    }

    return null;
}

export default React.memo(FooterContainer);
