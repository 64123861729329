import React from 'react';

function ScreenReaderContainer() {
    return (
        <div
            className="sr-only"
            id="srp-external-link"
        >
            (opens in new window)
        </div>
    );
}

export default ScreenReaderContainer;
