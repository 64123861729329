import { createSelector, DuckSelector, objectDuckCreator } from '@atc/modular-redux';

export default objectDuckCreator({
    store: 'cpoContent',
    initialState: {},
}).extend({
    selectors: {
        selectCertifiedContentSimple: new DuckSelector((selectors) => createSelector(
            selectors.getDuckState,
            ({ certifiedContentSimple = {} }) => certifiedContentSimple,
        )),
    },
});
