import { match } from 'path-to-regexp';

import { pageNames } from '@atc/bonnet-paths';
import * as routes from '@atc/bonnet-routes';

import getWebPathname from '@/utilities/getWebPathname';

/** ************ START: NEXTJS required route matching hackery! *************/
// NextJS has required some innovative routing solutions to allow their file based router functionality to handle findcars
// incredibly complicated SEO rules

const processedRoutes = { ...routes };

// Ford's landing page route is actually '/' but we re-write it internally to '/fordblueadvantage/landing-page'
// so we need to add it to the routes before the routes are processed
processedRoutes.fordlanding = [].concat(processedRoutes.fordlanding, '/cars-for-sale/fordblueadvantage/landing-page');

// SRP route is essentially a catch all route given its complexity and with every parameter being optional
// we must extract it from the original collection and place it to the end of the routes array
const { srp, ...rest } = processedRoutes;
const findCarRoutes = Object.entries(rest);
findCarRoutes.push(['srp', srp]);

function getPath({ asPath = '', req }) {
    let pathname = asPath.split('?')[0];

    // TODO: BONNET-NEXT is this a bug?  There is nothing on the intertubes about it
    if (req) {
        pathname = getWebPathname(pathname);
    }

    return pathname;
}

export default function withSeoDecodedPath() {
    return async (ctx) => {

        let matchResult;
        let currentPage;

        // Derive the current path from the ctx
        const path = getPath(ctx);

        // Loop through all the legacy route regexps and attempt to match any with the current path
        // and bail out if we find one
        findCarRoutes.some(([pageName, route]) => {

            const decodeUrl = match(route, { decode: decodeURIComponent });
            const result = decodeUrl(path);
            if (result) {
                matchResult = result;
                currentPage = pageName;
                return true;
            }

            return false;
        });

        if (matchResult) {
            // The SRP route is essentially a catch-all slug that will take any path segment after `cars-for-sale`
            // and add it to the ctx.query as the `searchresults` query param value.  We do not use this and it needs to be removed.
            // It is only named searchresults in order to identeify to developers what page the catch-all directs to
            if (currentPage === pageNames.SEARCH_RESULTS) {
                delete ctx.query.searchresults;
            }

            ctx.query = {
                ...ctx.query,
                ...matchResult.params,
            };
        }
    };
}
