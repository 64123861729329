import React from 'react';

import { LazyRender } from 'reaxl';
import { useFeatures } from 'reaxl-features';

function LazyComponent({
    children,
    componentClass: Component = 'div',
    componentProps,
    height,
    fallback = null,
    threshold,
    width,
}) {
    const {
        lazy_component_threshold: [, { defaultThreshold }],
    } = useFeatures(['lazy_component_threshold']);

    return (
        <LazyRender
            fallback={fallback}
            componentClass={Component}
            componentProps={componentProps}
            threshold={threshold || defaultThreshold}
            height={height}
            width={width}
        >
            {children}
        </LazyRender>
    );
}

export default LazyComponent;
