import qs from 'querystring';

import _union from 'lodash/union';

import { fetchJSON } from '@bonnet/next/fetch';

import { createSelector, DuckSelector } from '@atc/modular-redux';

import { resultsDuckCreator } from 'axl-ducks';

import { setCompareListingsIds } from '@/utilities/compareListingsHandler';

import {
    eLotResultsDuck,
    inventoryDuck,
    ownersDuck,
} from '.';

const compareListingsDuck = resultsDuckCreator({
    store: 'compare',
    initialState: {
        loading: true,
        initialLoad: true,
        activeResults: [],
    },
}).extend({
    creators: () => ({
        addToCompare: (listingId) => async (dispatch, getState) => {
            const state = getState();
            const currentListingIds = compareListingsDuck.selectors.getActiveListingIds(state) || [];

            if (listingId && !currentListingIds.includes(listingId) && currentListingIds.length < 5) {
                const newListingIds = currentListingIds.concat(listingId);
                dispatch(compareListingsDuck.creators.setActiveResults(newListingIds));

                setCompareListingsIds({ listingIds: newListingIds });
            }
        },

        clearAll: () => async (dispatch) => {
            dispatch(compareListingsDuck.creators.setActiveResults([]));

            setCompareListingsIds({ date: undefined, listingIds: [] });
        },

        loadListingsData: (comparedListingIds = [], numRecords = 3, loadELot = false) => async (dispatch, getState) => {
            if (Array.isArray(comparedListingIds) && comparedListingIds.length > 0) {
                // limit to 3 most recent listings
                const recentListingIds = comparedListingIds.length > numRecords ? comparedListingIds.slice(comparedListingIds.length - numRecords) : comparedListingIds;

                const options = {
                    headers: {
                        'X-fwd-svc': 'atc',
                    },
                    credentials: 'same-origin',
                    circuitBreaker: {
                        id: 'compareListings',
                        timeout: 3000,
                        resetTimeout: 30000,
                        fallback: {},
                    },
                };

                const {
                    listings = [],
                    owners = [],
                } = await fetchJSON('/rest/lsc/listing?' + qs.stringify({ listingId: recentListingIds }), options);

                dispatch(ownersDuck.creators.addOwners(owners));
                dispatch(inventoryDuck.creators.addInventory(listings));

                // get the latest state (capable of changing when fetching data)
                const state = getState();
                const currentActiveListingIds = compareListingsDuck.selectors.getActiveListingIds(state);

                // set the unique listingId to activeResult
                const listingIds = listings.map((listing) => listing.id);
                const activeResults = _union(currentActiveListingIds, listingIds);
                dispatch(compareListingsDuck.creators.setActiveResults(activeResults));

                if (loadELot && comparedListingIds.length < 5 && listings.length > 0) {
                    dispatch(eLotResultsDuck.creators.loadELotData({
                        addToCompare: true,
                        excludedListingIds: recentListingIds,
                        listingCriteria: listings?.[listings.length - 1],
                    }));
                } else {
                    dispatch(eLotResultsDuck.creators.setActiveResults([]));
                }
            }
        },

        removeFromCompare: (listingId) => async (dispatch, getState) => {
            const state = getState();
            const currentListingIds = compareListingsDuck.selectors.getActiveListingIds(state) || [];
            const newListingIds = [];
            currentListingIds.forEach((currentId) => {
                if (currentId !== listingId) {
                    newListingIds.push(currentId);
                }
            });

            dispatch(compareListingsDuck.creators.setActiveResults(newListingIds));

            setCompareListingsIds({ listingIds: newListingIds });
        },
    }),
    selectors: () => ({
        getActiveListingIds: new DuckSelector((selectors) => createSelector(
            selectors.getDuckState,
            ({ activeResults = [] }) => activeResults,
        )),
    }),
});

export default compareListingsDuck;
