import url from 'url';

import { injectScript } from 'atc-js';

import timingUtil from './timingUtil';

const ThirdPartyScripts = {

    executeIfAble(value) {
        return typeof value === 'function' ? value() : value;
    },

    loadScript(options) {
        let src = this.executeIfAble(options.src);
        const secureSrc = options.secureSrc && this.executeIfAble(options.secureSrc);

        if (secureSrc && document.location.protocol === 'https:') {
            // instead of just 'src = secureSrc;' we parse the url and force the protocol
            const forceHttpsSrc = url.parse(secureSrc, false, true);
            forceHttpsSrc.protocol = 'https:';
            src = url.format(forceHttpsSrc);
        }

        return src
            ? injectScript(src, { cacheKill: !!options.bustCache })
            : Promise.reject(new Error('no src for script'));
    },

    processScripts(scriptObject, synchronous) {
        // Convert to an array
        this.scriptQueue = Object.entries(scriptObject)
            // Preserve keys in the name field
            .map(([key, value]) => value && ({ name: key, ...value }))
            // Remove any entries whose value was falsey
            .filter((x) => x);

        let readyList = this.scriptQueue;

        if (synchronous) {
            // Limit readyList to the first script in the queue
            readyList = this.scriptQueue.shift();
        } else {
            // Prevent recursion in runScripts
            this.scriptQueue = [];
        }
        this.runScripts(readyList);
    },

    runScripts(scriptList) {
        scriptList.forEach((script) => {
            timingUtil.markUserTime('fetch-' + script.name + '-start');
            this.loadScript(script).then(() => {
                if (script.callback) {
                    script.callback();
                }
                timingUtil.markUserTime('fetch-' + script.name + '-end');
            }, () => {
                // Promise was rejected
                timingUtil.markUserTime('fetch-' + script.name + '-cancelled');
            }).then(() => {
                // If the queue contains script remove the first one and run it
                if (this.scriptQueue.length) {
                    this.runScripts(this.scriptQueue.shift());
                }
            });
        });
    },
};

export default ThirdPartyScripts;
