import qs from 'querystring';

const consoleMessage = (level) => {
    const serverPrefix = level.toUpperCase() + ':';
    return (category, ...params) => {
        if (typeof window !== 'undefined') {
            const urlParams = qs.parse(window.location.search.substr(1));

            // For efficiency, check if debugging is even on.
            if (window.console && urlParams.debug) {
                const allowedCategories = urlParams.debug.split(',');

                if (urlParams.debug === 'ALL' || allowedCategories.indexOf(category) !== -1) {
                    console[level](`${category}:`, ...params);
                }

            }
        } else {
            // On the server
            console.log(serverPrefix, `${category}:`, ...params);
        }
    };
};

const Debug = {
    log: consoleMessage('log'),
    info: consoleMessage('info'),
    warn: consoleMessage('warn'),
    error: consoleMessage('error'),
};

export default Debug;
