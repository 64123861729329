import { getKbbGlobalNavConfiguration } from '@atc/bonnet-reference';

import { brands } from 'reaxl-brand';

import { kbbPageDataDuck } from '@/ducks';

export default function withKBBPageData() {
    return async (ctx) => {
        const { brand } = ctx.data;
        const isKbb = brand === brands.KBB_BRAND;

        // only load in KBB brand and in SSR mode
        if (typeof window === 'undefined' && isKbb) {
            try {
                // get KBB global navigation configuration
                const navigationConfiguration = await getKbbGlobalNavConfiguration();

                if (navigationConfiguration) {
                    ctx.store.dispatch(kbbPageDataDuck?.creators?.setKeys({
                        navigation: navigationConfiguration,
                    }));
                } else {
                    // eslint-disable-next-line no-console
                    console.error('Error fetching kbb global navigation configuration - no data found');
                }
            } catch (error) {
                // eslint-disable-next-line no-console
                console.error('Error fetching kbb global navigation configuration: ', error);
            }
        }
    };
}
