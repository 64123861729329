import { createSelector, DuckSelector } from '@atc/modular-redux';

import { resultsDuckCreator } from 'axl-ducks';

import { inventoryDuck, ownersDuck } from '.';

const urgencyDriverSort = (conditionA, conditionB) => {
    if (conditionA) {
        if (conditionB) return 0;
        return -1;
    }
    if (conditionB) return 1;
    return null;
};

const urgencyDriverSortFunctions = [
    // 1. reduced price
    (a, b) => urgencyDriverSort(a.isReducedPrice, b.isReducedPrice),
    // 2. view count > 3
    (a, b) => urgencyDriverSort(a.viewCount > 3, b.viewCount > 3),
    // 3. great price
    (a, b) => urgencyDriverSort(
        a.pricingDetail?.dealIndicator === 'Great',
        b.pricingDetail?.dealIndicator === 'Great'
    ),
    // 4. good price
    (a, b) => urgencyDriverSort(
        a.pricingDetail?.dealIndicator === 'Good',
        b.pricingDetail?.dealIndicator === 'Good'
    ),
];

const cbhVehicleInterestsDuck = resultsDuckCreator({
    store: 'cbhVehicleInterests',
    initialState: {
        activeResults: [],
    },
}).extend({
    creators: () => ({
        loadCbhVehicleInterestsResults: (results, owners) => async (dispatch, getState) => {
            dispatch(cbhVehicleInterestsDuck.creators.setActiveResults([...results]));
            dispatch(inventoryDuck.creators.addInventory([...results]));
            dispatch(ownersDuck?.creators?.addOwners([...owners]));
        },
    }),
    selectors: () => ({
        getSortedVehicles: new DuckSelector((selectors) => createSelector(
            selectors.getActiveResults,
            (activeResults) => activeResults.sort((a, b) => {
                // abstracted to reduce cognitive complexity for sonar
                for (const sortFunction of urgencyDriverSortFunctions) {
                    const result = sortFunction(a, b);
                    if (result !== null) return result;
                }
                return 0;
            })
        )),
        getFirstVehicle: new DuckSelector((selectors) => createSelector(
            selectors.getSortedVehicles,
            (activeResults) => activeResults?.[0]
        )),
    }),
});

export default cbhVehicleInterestsDuck;
