import _get from 'lodash/get';

import { createAction, objectDuckCreator } from '@atc/modular-redux';

export default objectDuckCreator({
    namespace: '',
    store: 'birf',
    initialState: {
        pageData: {},
    },
}).extend({
    creators: (duck) => ({
        setState: createAction(duck.types.SET_STATE),
    }),
    selectors: {
        getPageData: (state) => _get(state, 'birf.pageData', {}),
        getPageBirf: (state) => _get(state, 'birf.pageData.page.BIRF'),
        getPgInst: (state) => _get(state, 'birf.pageData.page.BIRF.pg_inst', ''),
    },
    reducer: (state, action, { types }) => {

        switch (action.type) {
            case types.SET_STATE: {
                const pageData = action.payload.pageData || {};

                const birfInitialState = {
                    pageData: action.payload.dataIsland || {},
                };

                //  BI Search String used on SRP
                if (pageData.biSearchString) {
                    birfInitialState.searchData = '&cmp=srch&et=srch' + pageData.biSearchString;
                }

                //  BI Impression Data used on VDP
                if (pageData.overallImpressionData) {
                    birfInitialState.overallImpressionData = pageData.overallImpressionData;
                }

                return birfInitialState;
            }

            default:
                return state;
        }
    },
    types: [
        'SET_STATE',
    ],
});
