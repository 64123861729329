import React from 'react';

import dynamic from 'next/dynamic';

import { useBrand } from 'reaxl-brand';

const AtcTheme = dynamic(() => import('@/theme/AtcTheme'));
const KbbTheme = dynamic(() => import('@/theme/KbbTheme'));
const FordTheme = dynamic(() => import('@/theme/FordTheme'));
const DemoTheme = dynamic(() => import('@/theme/DemoTheme'));
const PreviewAtcTheme = dynamic(() => import('@/theme/PreviewAtcTheme'));

export default function ThemeLoader() {

    const { brand, brands } = useBrand();

    switch (brand) {
        case brands.KBB_BRAND: {
            return <KbbTheme />;
        }

        case brands.FORD_BRAND: {
            return <FordTheme />;
        }

        case brands.DEMO_BRAND: {
            return <DemoTheme />;
        }

        case brands.PREVIEW_BRAND: {
            return <PreviewAtcTheme />;
        }

        case brands.ATC_BRAND:
        default: {
            return <AtcTheme />;
        }
    }
}
