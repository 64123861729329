import dynamic from 'next/dynamic';

import { useBrand } from 'reaxl-brand';

const AtcFooter = dynamic(() => import('@/components/AtcFooter/index.js'), {});
const KBBFooter = dynamic(() => import('@/components/KBBFooter/index.js'), {});
const FordFooter = dynamic(() => import('@/components/FordFooter/index.js'), {});
const DemoFooter = dynamic(() => import('@/components/DemoFooter/index.js'), {});

export default function AppFooter() {

    const { brand, brands } = useBrand();
    const { ATC_BRAND, KBB_BRAND, FORD_BRAND, DEMO_BRAND } = brands;

    let BrandedFooter;

    switch (brand) {
        case KBB_BRAND: {
            BrandedFooter = KBBFooter;
            break;
        }

        case FORD_BRAND: {
            BrandedFooter = FordFooter;
            break;
        }

        case DEMO_BRAND: {
            BrandedFooter = DemoFooter;
            break;
        }

        case ATC_BRAND:
        default: {
            BrandedFooter = AtcFooter;
        }
    }

    return (<BrandedFooter />);
}
