import { seoJoinSplitUrl } from 'atc-js';

export default {
    rootDomain: seoJoinSplitUrl('fordblueadvantage', 'com'),
    wwwUrl: seoJoinSplitUrl('www', 'fordblueadvantage', 'com'),
    openGraphImage: seoJoinSplitUrl('https', 'www', 'fordblueadvantage', 'com', '/content/static/img/partner/ford/open-graph_ford.png'),
    consumerPrivacyPage: seoJoinSplitUrl('https', 'www', 'fordblueadvantage', 'com', '/cars-for-sale/fordblueadvantage/consumer-privacy'),
    prod: {
        rootUrl: seoJoinSplitUrl('https', 'www', 'fordblueadvantage', 'com'),
        baseUrl: seoJoinSplitUrl('https', 'www', 'fordblueadvantage', 'com', '/'),
        inventoryImageUrl: seoJoinSplitUrl('img', 'fordblueadvantage', 'com'),
        landingPage: seoJoinSplitUrl('https', 'www', 'fordblueadvantage', 'com', '/cars-for-sale'),
        landingPageOGImage: seoJoinSplitUrl('https', 'www', 'fordblueadvantage', 'com', '/content/static/img/fba/landing/open-graph_ford.webp'),
        landingPageOGUrl: seoJoinSplitUrl('https', 'www', 'fordblueadvantage', 'com', '/cars-for-sale', '/'),
    },
    nonProd: {
        rootUrl: seoJoinSplitUrl('https', 'test', 'fordblueadvantage', 'com'),
        baseUrl: seoJoinSplitUrl('https', 'test', 'fordblueadvantage', 'com', '/'),
        inventoryImageUrl: seoJoinSplitUrl('images-test', 'fordblueadvantage', 'com'),
        landingPage: seoJoinSplitUrl('https', 'test', 'fordblueadvantage', 'com', '/cars-for-sale'),
        landingPageOGImage: seoJoinSplitUrl('https', 'test', 'fordblueadvantage', 'com', '/content/static/img/fba/landing/open-graph_ford.webp'),
        landingPageOGUrl: seoJoinSplitUrl('https', 'test', 'fordblueadvantage', 'com', '/cars-for-sale', '/'),
    },
};
