import { DuckSelector } from '@atc/modular-redux';

import { formSubmissionDuckCreator } from 'axl-ducks';

import ShareTextFetcher from '@/fetchers/vdp/ShareTextFetcher';

const shareTextSubmissionDuck = formSubmissionDuckCreator({
    store: 'shareTextSubmission',
    initialState: {},
}).extend({
    creators: () => ({
        textFriend: (data) => async (dispatch) => {
            try {
                const response = await ShareTextFetcher(data);
                if (response.errorMessage) {
                    dispatch(shareTextSubmissionDuck.creators.showServerError(response.errorMessage));
                } else {
                    dispatch(shareTextSubmissionDuck.creators.showSuccess(response.success));
                }
            } catch (err) {
                dispatch(shareTextSubmissionDuck.creators.showServerError(err));
            }
        },
    }),
    selectors: () => ({
        getTextSubmissionStatus: new DuckSelector((selectors) => (state) => selectors.getDuckState(state)),
    }),
});

export default shareTextSubmissionDuck;
