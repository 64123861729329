import { recentSearchDuckCreator } from 'axl-ducks';

import { getPersonalizationClientEnv } from './duckUtils';

const recentSearchDuck = recentSearchDuckCreator({
    ...getPersonalizationClientEnv(),
}).extend({
    reducer: (state, action, { types }) => {
        switch (action.type) {

            case types.SET_RECENT_SEARCHES: {
                return {
                    ...state,
                    recentSearchesAreSet: true, // is this supposed to be used somewhere?
                };
            }

            default:
                return state;
        }
    },
});

export default recentSearchDuck;
