import { fetchJSON } from '@bonnet/next/fetch';

export default (value = '', fetchTotalPayment = false) => {
    const id = 'paymentCalc';
    const errorObject = {
        error: 'Circuit Breaker for /rest/retailing/payments is open',
        rates: {},
    };

    const options = {
        circuitBreaker: {
            id,
            timeout: 5000,
            resetTimeout: 300000,
            fallback: errorObject,
        },
        onError: (error) => {
            if (window && window.newrelic) {
                window.newrelic.addPageAction(
                    'ThirdPartyError',
                    {
                        id,
                        component: 'PaymentCalculationFetcher',
                        message: 'National AVG Rate Fallback',
                        status: error?.status,
                        requestParams: value,
                    }
                );
            }
        },
    };

    options.headers = {
        'X-fwd-svc': 'atc',
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };

    const endpoint = `/rest/retailing/${fetchTotalPayment ? 'budget' : 'payments'}`;
    value = value.startsWith('?') ? value : '?' + value;

    return fetchJSON(endpoint + value, options);
};
