import { fetchJSON } from '@bonnet/next/fetch';

import { getConsumerMetadata } from 'reaxl-analytics';

export default async ({
    listingId,
    message,
    phoneNumber,
    userName,
}) => {
    const { myConsumerId } = await getConsumerMetadata();
    return fetchJSON('/cars-for-sale/share-text', {
        method: 'POST',
        body: {
            requestId: 'VDP2MOBILE' + listingId,
            alertConfigId: 'atc_pinpoint_sharevdp',
            dataMap: {
                mobile_number: phoneNumber?.replace(/[^\d]/g, ''),
                sms_message: userName + message,
                consumerId: myConsumerId,
            },
        },
    });
};
