import { stringDuckCreator } from '@atc/modular-redux';

import { bapDuckPreset } from '@/ducks/presets';

const bapActiveModelDuck = stringDuckCreator({
    ...bapDuckPreset,
    store: 'selectedModel',
    initialState: null,
}).extend({
    creators: (duck) => ({
        setActiveModel: (modelCode) => (dispatch) => {
            dispatch(duck?.creators?.setString(modelCode));
        },
    }),
});

export default bapActiveModelDuck;
