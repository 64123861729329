import { fetchJSON } from '@bonnet/next/fetch';

import { cpoContentDuck } from '@/ducks';

export default function withCPOcontent(configOption = {}) {
    return async (ctx) => {
        const options = {
            headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
            },
            circuitBreaker: {
                id: 'cpo_certifiedcontentsimple',
                timeout: 3000,
                resetTimeout: 30000,
                fallback: {},
            },
            ...configOption,
        };
        try {
            // TODO hitting s3 is a quick fix to reduce throughput, but this should be cached in memory.
            let contentPrefix = '/content/static';
            if (typeof window === 'undefined') {
                contentPrefix = process.env.CONTENT_BUCKET;
            }
            const certifiedContentSimple = await fetchJSON(contentPrefix + '/json/atc/cpo/certifiedcontentsimple.json', options);
            ctx.store.dispatch(cpoContentDuck.creators.assign({ certifiedContentSimple }));
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Error while fetching cpo content: ', error);
        }
    };
}
