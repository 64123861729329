import React from 'react';

import NextLink from 'next/link';

import { removeBasePath } from '@bonnet/next/base-path';

export function isInternal(props = {}) {
    const isInternalLink = [
        // custom defined external targets
        (testProps) => !testProps.external,
        (testProps) => testProps.rel !== 'external',
        // specified targets
        (testProps) => testProps.target !== '_blank',
        // tel or mailto or https hrefs
        (testProps) => !/^\w+:/.test(testProps.href),
        // protocoless hrefs
        (testProps) => !/^\/\//.test(testProps.href),
        // ensure that the current href is a /cars-for-sale link
        (testProps) => testProps.href?.startsWith(process.env.BASE_PATH),
    ].every((test) => test(props));

    return isInternalLink;
}

// Next's Link Component is only designed to work with SPA transitions.
// We need to generate a native anchor tag if we determine that the destination is to be
// outside of the application
export default function ReaxlNextLink(props) {
    const linkProps = { ...props };

    if (!isInternal(linkProps)) {
        // anchor content is supplied by props.children
        // eslint-disable-next-line jsx-a11y/anchor-has-content
        return <a {...linkProps} />;
    }

    // In test we can allow the href to be empty
    if (process.env.NODE_ENV === 'test') {
        if (!linkProps.href) {
            linkProps.href = '/mock-link-href';
        }
    }

    // Throw warning in development as nextjs requires next/link to have an href
    if (process.env.NODE_ENV === 'development') {
        if (!linkProps.href) {
            // eslint-disable-next-line no-console
            console.log(`NextJS Link requires href but none is defined.  Other props passed: ${JSON.stringify(props)}`);
        }
    }

    // Bypass if this is an external link and allow original link element to be returned
    const nextLinkProps = {
        ...linkProps,
        'data-next-link': true,
        // As NextJS Link automatically appends basePath to each url
        // we must remove any preceding basePath from the href prop
        href: removeBasePath(linkProps.href),
    };

    return <NextLink {...nextLinkProps} />;

}
