import { DuckSelector, objectDuckCreator } from '@atc/modular-redux';

const activeInteractionDuck = objectDuckCreator({
    store: 'activeInteraction',
    initialState: {
        // `one_click_lead: oneClickOptInLead`: on FF cleanup, this should change to winning value T/F
        isOneClickOptedIn: null,
    },
}).extend({
    selectors: {
        isOneClickOptedIn: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).isOneClickOptedIn),
    },
});

export default activeInteractionDuck;
