import React from 'react';

import { withCookies } from 'react-cookie';
import { connect } from 'react-redux';

import ThirdPartyScripts from '@/utilities/ThirdPartyScript';

import './VehicleSaveListener';

class BaseContainer extends React.Component {

    constructor(props) {
        super(props);

        this.processThirdPartyScripts = this.processThirdPartyScripts.bind(this);
        this.setIntVisCookie = this.setIntVisCookie.bind(this);
    }

    componentDidMount() {
        window.addEventListener('load', this.processThirdPartyScripts);

    }

    setIntVisCookie() {
        this.props.cookies.set('intVis', true, { path: '/' });
    }

    processThirdPartyScripts() {
        ThirdPartyScripts.processScripts(this.props.thirdPartyScripts);
        window.removeEventListener('load', this.processThirdPartyScripts);
    }

    render() {
        return null;
    }
}

const mapStateToProps = (state) => {

    const { akamaiCountryCode } = state.userAgent ?? {};

    return {
        akamaiCountryCode: akamaiCountryCode || 'US',
    };
};

export default connect(mapStateToProps)(withCookies(BaseContainer));
