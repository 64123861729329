/**
 * The minimum year to query reference service with is the current year -1
 * If no year is selected then use current year, only need to know if start year is greater than current
 * year b/c if start year is greater than current year then start year is equal to end year since atc
 * only offers vehicles one year newer than current year
 *
 * @param endYear - string: from srp filter selection
 * @param startYear - string: from srp filter selection
 * @param year - number: from build & price page
 * @returns {number} year
 */
export default function getPreorderYear({ endYear, startYear, year }) {
    const currentYear = new Date().getFullYear();

    const newYear = (Number(startYear) > currentYear) ? startYear : currentYear;
    const yearRange = endYear && startYear && (endYear === startYear) ? endYear : newYear;
    const selectedYear = year && (typeof year !== 'object') ? year : yearRange;

    const yearQuery = Number(selectedYear);
    return (yearQuery >= (currentYear - 1)) ? yearQuery : currentYear;
}
