import isBot from 'isbot-fast';
import _get from 'lodash/get';

import {
    globalConfigsDuck,
    userAgentDuck,
} from '@/ducks';

export default function getConfigs() {
    return async (ctx) => {
        // We only need to update these stores once on the server as it will not update during the lifecycle of the SPA
        // and will be populated on the client from the server hydration
        if (typeof window === 'undefined') {
            const deviceType = _get(ctx, 'req.device.type', 'desktop');

            ctx.store.dispatch(globalConfigsDuck.creators.setKeys({
                accelerateWidgetUrl: process.env.accelerateWidgetUrl,
                activEngageUrl: process.env.activEngageUrl,
                carNowUrl: process.env.CARNOW_URL,
                adsEnabled: process.env.adsEnabled || true,
                aemHost: process.env.aemHost,
                autoFiPreQualUrl: process.env.autoFiPreQualUrl,
                botsTrackingScriptEnabled: process.env.botsTrackingScriptEnabled || false,
                buyerConnectUrl: process.env.buyerConnectUrl,
                calculateStrongAttributesHost: process.env.calculateStrongAttributesHost,
                ccpaRecordId: process.env.ccpaRecordId,
                ccpaEndpoint: process.env.ccpaEndpoint,
                creditAppHost: process.env.creditAppHost,
                fordMotorCreditPreQualUrl: process.env.fordMotorCreditPreQualUrl,
                fordMotorCreditPreQualSource: process.env.fordMotorCreditPreQualSource,
                imageServerUrl: process.env.imageServerUrl,
                mobileSmsUrl: process.env.mobileSmsUrl,
                openWrapGlobalUrl: process.env.openWrapGlobalUrl,
                openWrapGlobalKBBUrl: process.env.openWrapGlobalKBBUrl,
                openWrapUrl: process.env.openWrapUrl,
                optmzEdgeWebClientSrc: process.env.optmzEdgeWebClientSrc,
                optmzWebClientSrc: process.env.optmzWebClientSrc,
                priceAdvisorApiUrl: process.env.priceAdvisorApiUrl,
                priceAdvisorApiKey: process.env.priceAdvisorApiKey,
                telmetricsKey: process.env.telmetricsKey,
                westlakePreQualUrl: process.env.westlakePreQualUrl,
                CREDIT_VERIFICATION_PROXY_TIMEOUT: process.env.CREDIT_VERIFICATION_PROXY_TIMEOUT,
                KBB_RESEARCH_PROXY_TIMEOUT: process.env.KBB_RESEARCH_PROXY_TIMEOUT,
                kbbBaseUrl: process.env.kbbBaseUrl,
                carNowHostUrl: process.env.CAR_NOW_HOST,
            }));

            ctx.store.dispatch(userAgentDuck.creators.setKeys({
                akamaiCountryCode: _get(ctx, 'req.akamai.edgescape.country_code', 'us').toLowerCase(),
                details: {
                    browser: {
                        robot: isBot(ctx.req ? ctx.req.headers['user-agent'] : navigator.userAgent),
                    },
                    operatingSystem: {
                        deviceType: deviceType === 'phone' ? 'Mobile' : 'Computer',
                    },
                },
            }));
        }
    };
}
