import indexArray from 'index-array';
import _isEmpty from 'lodash/isEmpty';

import { fetchJSON } from '@bonnet/next/fetch';

import { createSelector, DuckSelector, objectDuckCreator } from '@atc/modular-redux';

import { ownersDuck } from '.';

const processPreorderListingsData = ({ trimData, makeCode, modelCode, ownerData }) => {
    let preorderListings = [];
    const aggregatedPreorderOwners = {};

    const matchingOwners = ownerData.filter((owner) => owner?.owner?.[0]?.nameplate.includes(makeCode));
    const matchingOwner = matchingOwners?.[0]?.owner?.[0];

    // check for matching trim
    const exactTrim = trimData
        ? Object.values(trimData).find((trim) => trim.trimName === trim.atcModel)
        : null;

    // assuming newest year was fetched first in VRS data, only map first result, so if newer year is available, it will
    // display instead of current year

    const vrsTrim = trimData
        ? (exactTrim || Object.values(trimData).slice(0, 1))
        : [];

    if (vrsTrim && !_isEmpty(vrsTrim) && !_isEmpty(matchingOwner)) {
        preorderListings = (Array.isArray(vrsTrim) ? vrsTrim : [vrsTrim])
            .map((item) => {
                const {
                    imagePath,
                    atcMake: make,
                    atcModel: model,
                    nationalMSRP,
                    trimName,
                    year,
                } = item;
                // remove trim for now, until we find solution how to match filter selection trim with vrs data.
                const title = `${year || ''} ${make || ''} ${model || ''}`;

                /* We need preorder response data. owner has the same structure as the listing owner.
                * Or continue customizing here.
                */
                return {
                    id: `preorder-${make}-${model}-${year}`,
                    pricingDetail: {
                        salePrice: nationalMSRP,
                    },
                    make,
                    makeCode,
                    model,
                    modelCode,
                    imageUrl: imagePath,
                    ...(imagePath && {
                        images: {
                            sources: [
                                {
                                    alt: title,
                                    src: imagePath,
                                    title,
                                },
                            ],
                        },
                    }),
                    title,
                    trimCode: trimName,
                    year,
                    owner: matchingOwner,
                };
            });

        // ensure each owner is only added once under its unique id
        preorderListings.forEach((listing) => {
            Object.assign(aggregatedPreorderOwners, {
                [listing?.owner?.id]: listing.owner,
            });
        });
    }

    return { preorderListings, aggregatedPreorderOwners };
};

const fetchOwnerData = async ({ dealerId = '', makeCode = '', modelCode = '', year = '', zip = '' }) => {
    const searchQuery = { dealerId, makeCode, modelCode, year, zip };
    // convert dealerId param to ownerIdToBoost
    if (searchQuery.dealerId) {
        searchQuery.ownerIdToBoost = searchQuery.dealerId;
    }
    delete searchQuery.dealerId;

    const dealersRequestOptions = {
        query: {
            ...searchQuery,
        },
    };

    // returns first owner in bucket
    const ownerData = await fetchJSON('/rest/lsc/customorder/dealers', dealersRequestOptions);

    return ownerData?.customOrders || [];
};

const preorderDuck = objectDuckCreator({
    store: 'preorder',
    initialState: {
        loading: false,
        initialLoad: true,
        models: {},
    },
}).extend({
    selectors: () => ({
        getPreorder: new DuckSelector((selectors) => createSelector(
            selectors.getDuckState,
            ({ models }) => {
                if (!models) {
                    return null;
                }
                return Object.values(models).filter((listing) => !listing?.id?.includes('Trim'));
            },
        )),
        getInitialLoad: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).initialLoad),
        isLoading: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).loading),
    }),
    creators: ({ types }) => ({
        loadData: ({
            dealerId = '',
            makeModelPairs = [],
            makeCodeList,
            modelCodeList,
            vrsData,
            year,
            zip,
        }) => async (dispatch) => {
            dispatch(preorderDuck?.creators?.setKeys({ loading: true }));

            let makeCode = makeCodeList;
            let modelCode = modelCodeList;

            if (!(makeCodeList && modelCodeList)) {
                makeCode = makeModelPairs?.[0]?.makeCode;
                modelCode = makeModelPairs?.[0]?.modelCode;
            }
            const ownerData = await fetchOwnerData({ dealerId, makeCode, modelCode, year, zip });

            if (ownerData && !_isEmpty(ownerData) && vrsData && !_isEmpty(vrsData)) {
                const preorderListings = [];
                const aggregatedPreorderOwners = [];

                // find the amount of unique make model pairs to iterate through from the VRS store
                // currently there are only multiple pairs provided from the SRP
                for (let i = 0; i < makeModelPairs.length; i++) {
                    const makeKey = makeModelPairs?.[i]?.makeCode;
                    const modelKey = makeModelPairs?.[i]?.modelCode;

                    const trimData = vrsData?.[makeKey]?.[modelKey];

                    const preorderListingData = trimData && processPreorderListingsData({
                        makeCode: makeModelPairs?.[i]?.makeCode,
                        modelCode: makeModelPairs?.[i]?.modelCode,
                        ownerData,
                        trimData,
                    });

                    if (preorderListingData?.preorderListings && preorderListingData?.aggregatedPreorderOwners) {
                        preorderListings.push(...preorderListingData.preorderListings);
                        aggregatedPreorderOwners.push(...Object.values(preorderListingData?.aggregatedPreorderOwners));
                    }
                }

                dispatch(ownersDuck?.creators?.addOwners(aggregatedPreorderOwners));
                dispatch(preorderDuck?.creators?.addPreorder(preorderListings));

            } else {
                dispatch(preorderDuck?.creators?.clearPreorderListings());
            }

            dispatch(preorderDuck?.creators?.setKeys({ loading: false }));
        },
        // add pricing data for each trim
        loadTrimData: ({
            activeTrims,
        }) => async (dispatch, getState) => {

            const preorder = preorderDuck.selectors.getPreorder(getState());

            const additionalPreorder = [];
            if (activeTrims) {
                Object.values(activeTrims).forEach((trim, index) => {
                    const preorderId = `preorderTrim-${trim?.atcModelCode}-${index}`;
                    const preorderTrim = {
                        id: preorderId,
                        make: trim?.make,
                        model: trim?.model,
                        pricingDetail: {
                            salePrice: trim?.nationalMSRP,
                        },
                    };
                    additionalPreorder.push(preorderTrim);
                });
            }
            const mergedPreorder = [
                ...preorder,
                ...additionalPreorder,
            ];

            dispatch(preorderDuck?.creators?.addPreorder(mergedPreorder));
        },
        addPreorder: (data) => async (dispatch) => {
            let preorder = data;
            // If preorder is passed as an array index it by id for lookup
            if (Array.isArray(data)) {
                preorder = indexArray(data, 'id', { overwrite: true });
            }

            dispatch(preorderDuck?.creators?.setKeys({ models: preorder }));
        },
        // clear preorder listings cards but keep any other types of preorder items
        clearPreorderListings: () => async (dispatch, getState) => {
            const { preorder } = getState();

            const remainingItems = {};
            Object.entries(preorder).forEach(([key, value]) => {
                if (value?.id?.includes('Trim')) {
                    Object.assign(remainingItems, {
                        [key]: value,
                    });
                }
            });

            dispatch(preorderDuck?.creators?.setKeys({ models: remainingItems }));
        },
        // clear preorder trim data but keep any other types of preorder items
        clearPreorderTrims: () => async (dispatch, getState) => {
            const { preorder } = getState();

            const remainingItems = {};
            Object.entries(preorder).forEach(([key, value]) => {
                if (!value?.id?.includes('Trim')) {
                    Object.assign(remainingItems, {
                        [key]: value,
                    });
                }
            });

            dispatch(preorderDuck?.creators?.setKeys({ models: remainingItems }));
        },
        // clear preorder
        clearPreorder: () => ({
            type: types.CLEAR_PREORDER,
            payload: {},
        }),
    }),
    reducer: (state, action, { types }) => {
        switch (action.type) {
            case types.ADD_PREORDER: {
                return action.payload;
            }
            case types.CLEAR_PREORDER: {
                return {};
            }
            default:
                return state;
        }
    },
    types: [
        'ADD_PREORDER',
        'CLEAR_PREORDER',
    ],
});

export default preorderDuck;
