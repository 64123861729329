import { BonnetModule } from '@bonnet/next/modules';

import ctxMiddleware from './ctxMiddleware';
import duck from './duck';

const parsedQueryModule = new BonnetModule({
    duck,
    ctxMiddleware,
});

export default parsedQueryModule;
