import { DuckSelector } from '@atc/modular-redux';

import { formSubmissionDuckCreator } from 'axl-ducks';

import EmailFriendFetcher from '@/fetchers/vdp/EmailFriendFetcher';

const shareEmailSubmissionDuck = formSubmissionDuckCreator({
    store: 'shareEmailSubmission',
    initialState: {},
}).extend({
    creators: () => ({
        emailFriend: (data) => async (dispatch) => {
            const randomId = String(parseInt(Math.random() * 10000000000, 10));
            const requestData = {
                originPageUrl: data.shareLink,
                listingId: data.listingId,
                ownerId: data.ownerId,
                name: `${data.firstName ? data.firstName : ''} ${data.lastName ? data.lastName : ''}`,
                toEmail: data.to,
                toEmailAddress: data.to,
                emailAddress: data.from,
                fromEmailAddress: data.from,
                ipAddress: data.ip,
                clientRequestId: randomId,
                originPage: data.originPage,
                searchZip: data.searchZip,
                partnerName: data.partnerName,
                ccFlag: false,
                lnxCode: data.lnxCode,
                carIds: [data.listingId],
                leadType: 'EMAILTOFRIEND',
                cookieId: data.cookieId,
                myAtcUserLoggedIn: data.myAtcUserLoggedIn,
            };

            try {
                const response = await EmailFriendFetcher(requestData);
                if (response.errorMessage) {
                    dispatch(shareEmailSubmissionDuck.creators.showServerError(response.errorMessage));
                } else {
                    dispatch(shareEmailSubmissionDuck.creators.showSuccess(response));
                }
            } catch (err) {
                dispatch(shareEmailSubmissionDuck.creators.showServerError(err.errorMessage));
            }
        },
    }),
    selectors: () => ({
        getEmailSubmissionStatus: new DuckSelector((selectors) => (state) => selectors.getDuckState(state)),
    }),
});

export default shareEmailSubmissionDuck;
