import { DuckSelector, objectDuckCreator } from '@atc/modular-redux';

export default objectDuckCreator({
    store: 'requestParams',
    initialState: {},
}).extend({
    selectors: {
        getRequestParams: new DuckSelector((selectors) => (state) => selectors.getDuckState(state)),
        getZip: new DuckSelector((selectors) => (state) => selectors.getDuckState(state).zip),
    },
});
