import { stringDuckCreator } from '@atc/modular-redux';

import { bapDuckPreset } from '@/ducks/presets';

const bapModelInfoDuck = stringDuckCreator({
    ...bapDuckPreset,
    store: 'selectedTrimId',
    initialState: null,
}).extend({
    creators: (duck) => ({
        setActiveTrim: (trim) => (dispatch) => {
            if (trim) {
                dispatch(duck?.creators?.setString(trim.toString()));
            }
        },
    }),
});

export default bapModelInfoDuck;
