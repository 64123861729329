// consolidate kbb styles that do not exist as atc styles
// order body style by popularity/priority
// /kbbresearch/reference/vehicles endpoint body style possibilities

const vrsToAtcBodyStyleTable = {
    Sedan: 'Sedan',
    AWD4WD: 'SUV',
    Crossover: 'SUV',
    SUV: 'SUV',
    Trucks: 'Truck',
    Pickup: 'Truck',
    Coupe: 'Coupe',
    Convertible: 'Convertible',
    Wagon: 'Wagon',
    Van: 'Van',
    'Van/Minivan': 'Van',
    Hatchback: 'Hatchback',
    Luxury: 'Luxury',
    Commercial: 'Van',
};

const vrsToAtcBodyStyleTransformer = (bodyStyle) => vrsToAtcBodyStyleTable[bodyStyle] || bodyStyle;

export default vrsToAtcBodyStyleTransformer;
