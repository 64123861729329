import _get from 'lodash/get';

import { DuckSelector, objectDuckCreator } from '@atc/modular-redux';

const userAgentDuck = objectDuckCreator({
    store: 'userAgent',
    initialState: {
        details: {
            browser: {
                robot: false,
            },
        },
    },
}).extend({
    selectors: {
        isBot: new DuckSelector((selectors) => (state) => _get(selectors.getDuckState(state), 'details.browser.robot')),
    },
});

export default userAgentDuck;
