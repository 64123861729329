import React from 'react';

import hasDom from 'has-dom';

import { usePageEvent } from '@bonnet/next/use-page-event';

import { useFeatures } from 'reaxl-features';

// This is a method to get the active pages once a user navigates
const getOptlyPages = () => hasDom() && window.optimizelyEdge?.get('state').getActivePages();

const activateOptlyPage = (optlyPage, optlyPages = []) => {

    // Force deactivation of the page
    window.optimizelyEdge?.push({
        type: 'page',
        pageName: optlyPages[optlyPage].apiName,
        isActive: false,
    });

    // Reactivate the page. This will force Optimizely to evaluate the conditions again as if reloading the DOM.
    window.optimizelyEdge?.push({
        type: 'page',
        pageName: optlyPages[optlyPage].apiName,
    });
};
const OptimizelyEdgeContainer = React.memo(() => {
    const {
        optimizely_snippet: [snippetEnabled],
    } = useFeatures(['optimizely_snippet']);

    const { onPageEnter } = usePageEvent('optimielyEdge');

    onPageEnter(() => {
        // loop through all pages and force deactivation and then reactivate
        if (snippetEnabled && hasDom()) {
            setTimeout(() => {
                const optlyPages = getOptlyPages() || [];
                for (const page in optlyPages) {
                    // To remove eslint error(s)
                    if (Object.prototype.hasOwnProperty.call(optlyPages, page)) {
                        activateOptlyPage(page, optlyPages);
                    }
                }
            }, 200);
        }
    });

    return null;
});

export default OptimizelyEdgeContainer;
