import { objectDuckCreator } from '@atc/modular-redux';

const kbbPageDataDuck = objectDuckCreator({
    store: 'kbbPageData',
    initialState: {
        navigation: null,
    },
});

export default kbbPageDataDuck;
