import { createSelector, DuckSelector, objectDuckCreator } from '@atc/modular-redux';

import { cbhVehicleInterestsDuck } from '.';

const consumerBrowsingHistoryDuck = objectDuckCreator({
    store: 'cbhData',
    initialState: {
        kbbTradeIns: [],
    },
}).extend({
    creators: () => ({
        loadCbhData: ({ kbbTradeIns, leadCandidates = {} }) => async (dispatch, getState) => {
            // store kbb trade-ins for wallet
            dispatch(consumerBrowsingHistoryDuck.creators.assign({ kbbTradeIns }));
            // store lead candidates for SRP still interested alert
            const { listings = [], owners = [] } = leadCandidates;
            if (listings.length && owners.length) {
                dispatch(cbhVehicleInterestsDuck.creators.loadCbhVehicleInterestsResults(listings, owners));
            }
        },
    }),
    selectors: () => ({
        getCbhKbbTradeIns: new DuckSelector((selectors) => createSelector(
            selectors.getDuckState,
            ({ cbhData: { kbbTradeIns } }) => kbbTradeIns
        )),
    }),
});

export default consumerBrowsingHistoryDuck;
